import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Website integration - HTML5 stream for Full HD cameras",
  "path": "/Advanced_User/Website_Integration/HTML5_Stream_Full_HD_Cameras/",
  "dateChanged": "2020-12-04",
  "author": "Mike Polinowski",
  "excerpt": "Insert the HTML5 live video from your Full HD camera into your website.",
  "image": "./AU_SearchThumb_WebsiteIntegration_HD_Cams.png",
  "social": "/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Integration_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Website integration - HTML5 stream for Full HD cameras' dateChanged='2020-12-04' author='Mike Polinowski' tag='INSTAR IP Camera' description='Insert the HTML5 live video from your Full HD camera into your website.' image='/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.png' twitter='/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Webseiten_Einbindung/HTML5_Stream_Full_HD_Kameras/' locationFR='/fr/Advanced_User/Website_Integration/HTML5_Stream_Full_HD_Cameras/' crumbLabel="Website" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "website-integration---html5-stream-for-full-hd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#website-integration---html5-stream-for-full-hd-cameras",
        "aria-label": "website integration   html5 stream for full hd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Website integration - HTML5 stream for Full HD cameras`}</h1>
    <blockquote>
      <p parentName="blockquote">{`This manual is `}<strong parentName="p">{`only intended for 1080p Full HD camera models`}</strong>{`. For `}<strong parentName="p">{`720p HD models`}</strong>{` please see the HD guide `}<a parentName="p" {...{
          "href": "/en/Advanced_User/Website_Integration/HD_Camera_Integration/"
        }}>{`here`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "html5-video-stream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#html5-video-stream",
        "aria-label": "html5 video stream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTML5 Video Stream`}</h2>
    <p>{`With your INSTAR Full HD cameras you can integrate the HTML5 (plugin-free) video stream directly into a website. We have provided you with the required HTML and JavaScript code in our `}<a parentName="p" {...{
        "href": "http://wiki.instar.com/dl/Developer/html5instarfullhd.zip"
      }}>{`download area`}</a>{`.`}</p>
    <p>{`To test this code:`}</p>
    <ol>
      <li parentName="ol">{`Simply drag the HTML file and the folder with scripts into the active directory of your web server.`}</li>
      <li parentName="ol">{`rename the HTML file to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`index.html`}</code>{` and open it in your text or code editor`}</li>
      <li parentName="ol">{`specify in the file the address of your camera, the HTTP port, your camera login and the desired stream:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7ce29a5ed854ce076595f396d9b15254/2bef9/INSTAR_Embedded_HTML5_Video_Stream.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABHElEQVQY002Qa2+CMBhGsRcvtG9foAxEnQgtLXKJZrrFZUv84v7/X1rYsmTJ+XqS8zzBc3SSh4c0DzPen/fv3n+lu9sidgxqJn+A/xgGhoKZx56jDZbgou2nLD9EcRZgQboYO4GOg+WJ57pl2LCoZeiYan5lDgbK6yLrgyWYUDdCu5koBdhQea2GXPWryC63I9lfue54MtC4pdhQqChUDOo0G0VyDEJlZ7IisibKSNkI5TWOBfYcG3a4Mj2Q4o1mZ5q/0PWFFq90d6No52ipqoNwipn2EKiFtCF4VH2OHaKTWbvKj4vNiWwubH2i6TARTXcEUCXQBiE0BGr6J6/ARarTqtXqiLqO16V8MiQfeeqZrKicsjmYQO3v4e0bLfQfcTomiCgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ce29a5ed854ce076595f396d9b15254/e4706/INSTAR_Embedded_HTML5_Video_Stream.avif 230w", "/en/static/7ce29a5ed854ce076595f396d9b15254/d1af7/INSTAR_Embedded_HTML5_Video_Stream.avif 460w", "/en/static/7ce29a5ed854ce076595f396d9b15254/7f308/INSTAR_Embedded_HTML5_Video_Stream.avif 920w", "/en/static/7ce29a5ed854ce076595f396d9b15254/e3723/INSTAR_Embedded_HTML5_Video_Stream.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7ce29a5ed854ce076595f396d9b15254/a0b58/INSTAR_Embedded_HTML5_Video_Stream.webp 230w", "/en/static/7ce29a5ed854ce076595f396d9b15254/bc10c/INSTAR_Embedded_HTML5_Video_Stream.webp 460w", "/en/static/7ce29a5ed854ce076595f396d9b15254/966d8/INSTAR_Embedded_HTML5_Video_Stream.webp 920w", "/en/static/7ce29a5ed854ce076595f396d9b15254/a9a89/INSTAR_Embedded_HTML5_Video_Stream.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ce29a5ed854ce076595f396d9b15254/81c8e/INSTAR_Embedded_HTML5_Video_Stream.png 230w", "/en/static/7ce29a5ed854ce076595f396d9b15254/08a84/INSTAR_Embedded_HTML5_Video_Stream.png 460w", "/en/static/7ce29a5ed854ce076595f396d9b15254/c0255/INSTAR_Embedded_HTML5_Video_Stream.png 920w", "/en/static/7ce29a5ed854ce076595f396d9b15254/2bef9/INSTAR_Embedded_HTML5_Video_Stream.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7ce29a5ed854ce076595f396d9b15254/c0255/INSTAR_Embedded_HTML5_Video_Stream.png",
              "alt": "INSTAR Kamera HTML5 Videostream",
              "title": "INSTAR Kamera HTML5 Videostream",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`cam_host`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`192.168.178.21`}</code>{` (DDNS or local IP)`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`cam_port`}</strong>{` `}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`80`}</code>{` (Camera HTTP Port)`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`cam_user`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`admin`}</code>{` (User name for the camera Login)`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`cam_pass`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`instar`}</code>{` (User login for the camera Login)`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`cam_stream`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`12`}</code>{` (`}<strong parentName="li">{`11`}</strong>{` full resolution, `}<strong parentName="li">{`12`}</strong>{` middle resolution, `}<strong parentName="li">{`13`}</strong>{` small resolution)`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Then start your web server and open the provided page in your web browser.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      